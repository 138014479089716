import { NgModule } from '@angular/core';
import { Routes, RouterModule , ExtraOptions} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WhoWeAreComponent } from './whoWeAre/whoWeAre.component';
import { ContactUsComponent } from './get In Touch/contactUs.component';
import { BlogsComponent } from './blogs/blogs.component';
import { WhatWeDoComponent } from './whatWeDo/whatWeDo.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'who-We-Are', component: WhoWeAreComponent },
  { path: 'what-We-Do', component: WhatWeDoComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent}

];
const routerOptions: ExtraOptions={
  scrollPositionRestoration: 'enabled'
  ,anchorScrolling:'enabled'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {



 }
