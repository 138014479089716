<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>

<div *ngIf="web" style="height: 100%;">
    <div class="backGroundLayout" >
        <div class="body-Content">
          <label style="animation: bodyAnimation 1.5s;" class="title">WONDERING WHO WE ARE?</label>
          <br>
          <label style="animation: bodyAnimation 3.5s;" class="subTitle">WE LEAD BUSINESSES</label>
          <br>
          <label style="animation: bodyAnimation 5.5s;" class="subTitle">TO SUCCESS</label>
        </div>
        <button (click)="navigateToContactUs()"  style="animation: bodyAnimation 7.5s; font-family:'Montserrat'" class="getInTouchButton">
          LET'S GET IN TOUCH
        </button>
      </div>
      <div style="background-color: #FAFAFB; height: fit-content">
        <label class="bodyText" style="animation: myAnimation 1s; display: inline-block; "> <b>Welcome to a future where possibilities meet progress through strategic partnerships and development!</b></label>
        <br>
        <label class="bodyText2" style="animation: myAnimation 2s; display: inline-block; ">We’re food lovers by nature who crave leadership and are completely crazy about creative marketing.
           Food is Foodo’s passion and here comes our global power! We are specialized in the Food & Beverages sector,
            ready to transform your business by providing a bundle of soar ideas and freshly cool marketing solutions.
             Our sharp-minded team mobilizes creativity, brings ideas to life, and dives into the brand to deliver powerful stories.</label>
        <br>
        <label class="bodyText2" style="animation: myAnimation 3s; display: inline-block; ">Food binds people together and we, at Foodo, are here to implement tasty brand storytelling.
           We grow ideas, connect creatively with your target audience and cultivate market leadership from the inside out.</label>
        <br>
        <label class="bodyText2" style="animation: myAnimation 4s; display: inline-block; ">Wondering what your target market is looking for in a food brand? Well, our passionate team got all
           the answers. We undergo in-depth research of your target market and implement cool tailored strategies that innovate,
            inform, and inspire. It’s time to stand out from the crowd and optimize your sales funnel by turning thoughts into traffic.
             We help the business discover its very best through unique insights that boost visibility and conversion.
              Our clarity enables us to put our finger on the essence of your business and make the world know what you have to say.
        </label>
       <br>
        <label class="bodyText3" style="animation: myAnimation 5s; display: inline-block; ">Together we make a difference! Foodo believes that partnership towards a common vision creates
           winners. We fuse our skilled team with that of our client, capturing unparalleled trust.
           We make your customer the hero of your story and your employee the warrior behind your success!</label>
      </div>
</div>

<!--Mobile View-->
<div *ngIf="mobile" style="height: 100%; width: 100%;">
  <div class="backGroundLayout" >
    <div class="body-Content">
      <label style="animation: bodyAnimation 1.5s;" class="title">WONDERING WHO WE ARE?</label>
      <label style="animation: bodyAnimation 2.5s;" class="subTitle">WE LEAD BUSINESSES</label>
      <label style="animation: bodyAnimation 3.5s;" class="subTitle">TO SUCCESS</label>
    </div>
    <div style="width: 100%; text-align: center;">
      <button (click)="navigateToContactUs()" style="animation: bodyAnimation 4.5s;font-family:'Montserrat'" class="getInTouchButton">
      LET'S GET IN TOUCH
    </button>
    </div>
  </div>
  <div style="height: fit-content; width: 100%; background-color: #FAFAFB;"> 
    <label class="bodyText" style="animation: bodyTextAnimation 2.5s;"> <b>Welcome to a future where possibilities meet progress through strategic partnerships and development!</b></label>
    <br>
    <label class="bodyText2" style="animation: myAnimation 2s; display: inline-block; ">We’re food lovers by nature who crave leadership and are completely crazy about creative marketing.
       Food is Foodo’s passion and here comes our global power! We are specialized in the Food & Beverages sector,
        ready to transform your business by providing a bundle of soar ideas and freshly cool marketing solutions.
         Our sharp-minded team mobilizes creativity, brings ideas to life, and dives into the brand to deliver powerful stories.</label>
    <br>
    <label class="bodyText2" style="animation: myAnimation 3s; display: inline-block; ">Food binds people together and we, at Foodo, are here to implement tasty brand storytelling.
       We grow ideas, connect creatively with your target audience and cultivate market leadership from the inside out.</label>
    <br>
    <label class="bodyText2" style="animation: myAnimation 4s; display: inline-block; ">Wondering what your target market is looking for in a food brand? Well, our passionate team got all
       the answers. We undergo in-depth research of your target market and implement cool tailored strategies that innovate,
        inform, and inspire. It’s time to stand out from the crowd and optimize your sales funnel by turning thoughts into traffic.
         We help the business discover its very best through unique insights that boost visibility and conversion.
          Our clarity enables us to put our finger on the essence of your business and make the world know what you have to say.
    </label>
   <br>
    <label class="bodyText3" style="animation: myAnimation 5s; display: inline-block; ">Together we make a difference! Foodo believes that partnership towards a common vision creates
       winners. We fuse our skilled team with that of our client, capturing unparalleled trust.
       We make your customer the hero of your story and your employee the warrior behind your success!</label>
  </div>
</div>


