import { Component, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Model } from '../model/model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-what-We-Do',
  templateUrl: './whatWeDo.component.html',
  styleUrls: ['./whatWeDo.component.scss']
})
export class WhatWeDoComponent implements OnInit {

  web = false;
  mobile = false;
  public href: string = "";
  public screenWidth: any;

  businessDevelopment = false;
  businessLeaderShip = false;
  marketing = false;
  strategicConsultingAndPlanning = false;
  technologyConsulting = false;
  accountManagement = false;
  contentCreation = false;
  campaigns = false;
  connectionsPlanning = false;
  tvcProduction = false;
  branding = false;
  printingDesigns = false;
  websitesAndMobileApplication = false;
  eventsAndPlanningExecution = false;
  interiorDesign = false;


  constructor(private router: Router, public model: Model, private location: Location) {
    this.screenWidth = window.innerWidth;
    this.href = this.location.path();
    if(this.screenWidth > 1200){
      this.web = true;
    }
    if(this.screenWidth <= 1200){
      this.mobile = true;
    }
   }

  ngOnInit(): void {
    
  }

  navigateToContactUs(){
    this.router.navigateByUrl('/contact-us');
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  openDiv(division) {
    switch(division){
      case 'businessDevelopment': this.businessDevelopment = !this.businessDevelopment;
      break; 
      case 'businessLeaderShip': this.businessLeaderShip = !this.businessLeaderShip;
      break;
      case 'marketing': this.marketing = !this.marketing;
      break;
      case 'strategicConsultingAndPlanning': this.strategicConsultingAndPlanning = !this.strategicConsultingAndPlanning;
      break;
      case 'technologyConsulting' : this.technologyConsulting = ! this.technologyConsulting;
      break;
      case 'accountManagement' : this.accountManagement = !this.accountManagement;
      break;
      case 'contentCreation': this.contentCreation = ! this.contentCreation;
      break;
      case 'campaigns': this.campaigns = !this.campaigns;
      break;
      case 'connectionsPlanning': this.connectionsPlanning = !this.connectionsPlanning;
      break;
      case 'tvcProduction': this.tvcProduction = !this.tvcProduction;
      break;
      case 'branding': this.branding = !this.branding;
      break;
      case 'printingDesigns': this.printingDesigns = !this.printingDesigns;
      break;
      case 'websitesAndMobileApplication': this.websitesAndMobileApplication = !this.websitesAndMobileApplication;
      break;
      case 'eventsAndPlanningExecution': this.eventsAndPlanningExecution = !this.eventsAndPlanningExecution;
      break;
      case 'interiorDesign': this.interiorDesign = ! this.interiorDesign;
    }
  }
}

