<div *ngIf="web" style="height: 100%;">
    <div class="backGroundLayout">
        <div class="body-Content">
            <label class="title" style="font-family: 'Poppins';">Terms & Conditions</label>
        </div>
        <button (click)="navigateToContactUs()" class="getInTouchButton" style="font-family: 'Montserrat';">
            LET'S GET IN TOUCH
        </button>
    </div>
    <div style="background-color: #FAFAFB; height: fit-content;">
        <label class="bodyText2" style="font-family: 'Poppins';">
            These terms and conditions ("Terms") govern your use of the Foodo website and the services provided by Foodo (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access or use the Services.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>1. Description of Services</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            Foodo provides full marketing services tailored to the Food & Beverage (F&B) sector, including but not limited to business development, strategic consulting, digital marketing, branding, advertising, and promotional campaigns. Our Services are designed to help businesses in the F&B industry grow and succeed.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>2. User Conduct</b>
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            When using the Services, you agree to:
            <ul class="privacy-list" style="margin-top: 5px; margin-bottom: 5px;">
                <li>Comply with all applicable laws and regulations.</li>
                <li>Provide accurate and truthful information.</li>
                <li>Respect the intellectual property rights of Foodo and others.</li>
                <li>Not engage in any activity that may interfere with or disrupt the Services.</li>
            </ul>
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>3. Intellectual Property</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            All content and materials provided by Foodo, including but not limited to logos, trademarks, text, images, and designs, are the property of Foodo or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, or distribute any of our intellectual property without our prior written consent.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>4. Privacy</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>5. Limitation of Liability</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            In no event shall Foodo or its affiliates be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Services, even if advised of the possibility of such damages. Our total liability to you for any claim arising out of or relating to these Terms or the Services shall not exceed the amount paid by you, if any, for the use of the Services.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>6. Indemnification</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            You agree to indemnify, defend, and hold harmless Foodo and its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Services or any breach of these Terms.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>7. Governing Law and Jurisdiction</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            These Terms shall be governed by and construed in accordance with the laws of Lebanon, without regard to its conflict of law provisions. Any dispute arising out of or in connection with these Terms or the Services shall be subject to the exclusive jurisdiction of the courts located in Lebanon.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>8. Changes to Terms and Conditions</b>
        </label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            Foodo reserves the right to modify or revise these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our website. By continuing to use the Services after such changes, you agree to be bound by the revised Terms.
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            <b>Contact Us</b>
        </label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins'; margin-bottom: 30px;">
            If you have any questions or concerns about these Terms or the Services, please contact us at:
            <br>
            Foodo
            <br>
            <div class="support_container">
            <span class="support_link"> Email : </span> <a class="support_link" href="mailto:support@foodoglobal.com">support@foodoglobal.com</a><br>
            <span class="support_link"> Phone : </span> <a class="support_link" href="tel:0096181813118">00961 81 81 31 18</a>
       </div>
        </label>
    </div>
</div>


