<!-- <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'> -->
<script>
  // JavaScript to add animation class when element comes into view
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function handleScrollAnimation() {
    var elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach(function(el) {
      if (isElementInViewport(el)) {
        el.classList.add('animated');
      }
    });
  }

  // Initial check
  handleScrollAnimation();

  // Event listener for scroll
  window.addEventListener('scroll', function() {
    handleScrollAnimation();
  });
</script>
<div *ngIf="web">
  <div  style="height: 100%;">
      <div class="backGroundLayout">
          <div class="body-Content">
              <label class="title animated" style="animation: bodyAnimation 2s;">UP TO DATE?</label>
              <br>
              <label class="subTitle animated" style="animation: bodyAnimation 2s;">YOU HAVE TO BE UP TO</label>
              <br>
              <label class="subTitle animated" style="animation: bodyAnimation 2s;"> TOMORROW</label>
          </div>
          <div class="getIn-btn">
          <button (click)="navigateToContactUs()" class="getInTouchButton" style="animation: bodyAnimation 3s; font-family:'Montserrat'">
              LET'S GET IN TOUCH
          </button>
          </div>
      </div>
      <div style="background-color: #FAFAFB; height: fit-content;" class="blog_container">
         
          <div class="blogsContentRow" >
            <div class="blogsContentImage" style=" animation: bodyAnimation 2s;">
                <img class="blogsImage" src="../../assets/Images/blogs/The Latest Trends, Systems, and Software.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">The Latest Trends, Systems, and Software</label>
              <label class="blogsContentDetails">
                We are always on the leading edge of trends and technology.
                As a performance-driven agency, we use analytical reports, data,
                and software to target the right offers, to the right customers,
                at the right time.
              </label>
            </div>
          </div>

          <div class="blogsContentRow" style="margin-top: 30px;">
            <div class="blogsContentImage" style="animation: bodyAnimation 2s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Leverage Their Experience and Talents.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Leverage Their Experience and Talents</label>
              <label class="blogsContentDetails">
                Food passion is behind our successful story. We work with various technologies and clients.
                Therefore, we have the experience and data to prove what campaigns 
                are most effective for the food industry
              </label>
            </div>
          </div>

          <div class="blogsContentRow" style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 3s;">
                <img class="blogsImage" src="../../assets/Images/blogs/prome.jpg">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Fresh Perspective and Ideas</label>
              <label class="blogsContentDetails">
                Businesses will benefit from our new ideas and perspectives that they 
                may not see when they’re so close to a project. Our fresh eyes and new 
                blood can bring a tired campaign back to life.
              </label>
            </div>
          </div>

          <div class="blogsContentRow"style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 4s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Scalability on Demand_.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Scalability on Demand</label>
              <label class="blogsContentDetails">
                Businesses can scale their efforts up or down depending on new products, seasonality, or for any reason.
                With an internal team, With Foodo, they just pick up the phone.
              </label>
            </div>
          </div>

          <div class="blogsContentRow"style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 5s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Work with the Experts.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Work with the Experts</label>
              <label class="blogsContentDetails">
                We provide you with food experts to make the most of marketing opportunities and grow your business.
                Our team forms a pool of specialists, and the pool is deep. Today’s inbound marketing requires many areas of discipline such as SEO, content, social media, branding, and paid ads.
              </label>
            </div>
          </div>

          <div class="blogsContentRow" style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 6s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Quantifiable Results.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Quantifiable Results</label>
              <label class="blogsContentDetails">
                We bake brand new recipes and that’s our power. We measure all metrics to see what is working and where the ad spend does the most good.
              </label>
            </div>
          </div>

          <div class="blogsContentRow" style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 7s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Continuity and Work Overflow.webp">
            </div>
            <div class="blogsContentText" >
              <label class="blogsContentTitle">Continuity and Work Overflow</label>
              <label class="blogsContentDetails">
                We come to you with the most up-to-date solutions to grow 
                your business. We help during times of peak demand and work overflow situations, providing continuity to the brand message across all 
                channels.</label>
            </div>
          </div>

          <div class="blogsContentRow" style="margin-top: 30px;">
            <div class="blogsContentImage" style=" animation: bodyAnimation 8s;">
                <img class="blogsImage" src="../../assets/Images/blogs/Broader Skillsets.webp">
            </div>
            <div class="blogsContentText" style=" margin-bottom: 100px;">
              <label class="blogsContentTitle">Broader Skillsets</label>
              <label class="blogsContentDetails">
                Businesses’ staff may not possess all the skills required
                for a comprehensive campaign such as graphic design or SEO.
                However, our talent and resources can bring businesses’ vision
                to fruition successfully.
              </label>
            </div>
          </div>
          <div style="height: 80px;">

          </div>
        </div>
  </div>
</div>
<div *ngIf="mobile">
  <div  style="height: 100%;">
    <div class="backGroundLayout">
        <div class="body-Content">
            <label class="title animated" style="animation: bodyAnimation 2s;">UP TO DATE?</label>
            <br>
            <label class="subTitle animated" style="animation: bodyAnimation 2s;">YOU HAVE TO BE UP TO</label>
            <br>
            <label class="subTitle animated" style="animation: bodyAnimation 2s;"> TOMORROW</label>
        </div>
        <button (click)="navigateToContactUs()" class="getInTouchButton" style="animation: bodyAnimation 3s; font-family:'Montserrat'">
            LET'S GET IN TOUCH
        </button>
    </div>
    <div style="background-color: #FAFAFB; height: fit-content;"  class="blog_container">
        <div class="blogsContentRow" style="margin-top: 20px;">
        </div>
        <div class="blogsContentRow" >
          <div class="blogsContentImage" style=" animation: bodyAnimation 2s;">
              <img class="blogsImage" src="../../assets/Images/blogs/The Latest Trends, Systems, and Software.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">The Latest Trends, Systems, and Software</label>
            <label class="blogsContentDetails">
              We are always on the leading edge of trends and technology.
              As a performance-driven agency, we use analytical reports, data,
              and software to target the right offers, to the right customers,
              at the right time.
            </label>
          </div>
        </div>

        <div class="blogsContentRow" style="margin-top: 30px;">
          <div class="blogsContentImage" style="animation: bodyAnimation 2s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Leverage Their Experience and Talents.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Leverage Their Experience and Talents</label>
            <label class="blogsContentDetails">
              Food passion is behind our successful story. We work with various technologies and clients.
              Therefore, we have the experience and data to prove what campaigns 
              are most effective for the food industry
            </label>
          </div>
        </div>

        <div class="blogsContentRow" style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 3s;">
              <img class="blogsImage" src="../../assets/Images/blogs/prome.jpg">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Fresh Perspective and Ideas</label>
            <label class="blogsContentDetails">
              Businesses will benefit from our new ideas and perspectives that they 
              may not see when they’re so close to a project. Our fresh eyes and new 
              blood can bring a tired campaign back to life.
            </label>
          </div>
        </div>

        <div class="blogsContentRow"style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 4s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Scalability on Demand_.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Scalability on Demand</label>
            <label class="blogsContentDetails">
              Businesses can scale their efforts up or down depending on new products, seasonality, or for any reason.
              With an internal team, With Foodo, they just pick up the phone.
            </label>
          </div>
        </div>

        <div class="blogsContentRow"style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 5s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Work with the Experts.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Work with the Experts</label>
            <label class="blogsContentDetails">
              We provide you with food experts to make the most of marketing opportunities and grow your business.
              Our team forms a pool of specialists, and the pool is deep. Today’s inbound marketing requires many areas of discipline such as SEO, content, social media, branding, and paid ads.
            </label>
          </div>
        </div>

        <div class="blogsContentRow" style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 6s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Quantifiable Results.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Quantifiable Results</label>
            <label class="blogsContentDetails">
              We bake brand new recipes and that’s our power. We measure all metrics to see what is working and where the ad spend does the most good.
            </label>
          </div>
        </div>

        <div class="blogsContentRow" style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 7s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Continuity and Work Overflow.webp">
          </div>
          <div class="blogsContentText" >
            <label class="blogsContentTitle">Continuity and Work Overflow</label>
            <label class="blogsContentDetails">
              We come to you with the most up-to-date solutions to grow 
              your business. We help during times of peak demand and work overflow situations, providing continuity to the brand message across all 
              channels.</label>
          </div>
        </div>

        <div class="blogsContentRow" style="margin-top: 30px;">
          <div class="blogsContentImage" style=" animation: bodyAnimation 8s;">
              <img class="blogsImage" src="../../assets/Images/blogs/Broader Skillsets.webp">
          </div>
          <div class="blogsContentText" style=" margin-bottom: 100px;">
            <label class="blogsContentTitle">Broader Skillsets</label>
            <label class="blogsContentDetails">
              Businesses’ staff may not possess all the skills required
              for a comprehensive campaign such as graphic design or SEO.
              However, our talent and resources can bring businesses’ vision
              to fruition successfully.
            </label>
          </div>
        </div>
        <div style="height: 80px;">

        </div>
      </div>
</div>
</div>
<!--Mobile view-->
