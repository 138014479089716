import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  @Input() itemImage;
  @Input() itemName;
  @Input() itemDescription;
  @Input() itemPrice;

  quantity;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }



}
