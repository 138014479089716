<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>

<div *ngIf="web" style="height: 100%;">
      <div class="backGroundLayout" >
        <div class="body-Content">
          <label style="animation: bodyAnimation 1.5s;" class="title">WONDERING WHAT WE DO?</label>
          <br>
          <label style="animation: bodyAnimation 3.5s;" class="subTitle">OUR PASSION MEETS</label>
          <br>
          <label style="animation: bodyAnimation 5.5s;" class="subTitle">YOUR SUCCESS</label>
        </div>
        <button (click)="navigateToContactUs()"  style="animation: bodyAnimation 7.5s; font-family:'Montserrat'" class="getInTouchButton">
          LET'S GET IN TOUCH
        </button>
      </div>
      <div style="background-color: #FAFAFB; height: fit-content">
        <div>
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('businessDevelopment')">
            <label class="bodyTextTitle" style="margin-top: 80px;"><b>Business Development</b></label>
          </div>
          <div *ngIf="businessDevelopment" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Elevate your F&B venture with our comprehensive business development services.
               From innovation to market strategy, we blend culinary expertise with strategic vision to create a recipe for your success.
                Let's craft a delectable journey together!
            </label>
          </div>

          <!--2nd Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('businessLeaderShip')">
            <label class="bodyTextTitle"><b>Business Leadership</b></label>
          </div>
          <div *ngIf="businessLeaderShip" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Experience the evolution with our strategic services that turn businesses into industry leaders.
               Through visionary leadership development and innovative organizational strategies, we pave the way for success.
               How about rewriting the narrative together, propelling your business into a league of its own!
            </label>
          </div>

          <!--3rd Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('marketing')">
            <label class="bodyTextTitle"><b>Marketing</b></label>
          </div>
          <div *ngIf="marketing" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Experience the power of strategic marketing tailored for success. We implement plans and targeted campaigns in order
               to amplify your brand presence and drive results. Our comprehensive services ensure your brand resonates,
                connecting with your audience in a meaningful way, covering all mediums online and offline.
                 We craft compelling content, foster engagement, and boost your brand visibility. It’s time to fuel your marketing success!
            </label>
          </div>

          <!--4rth Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('strategicConsultingAndPlanning')">
            <label class="bodyTextTitle"><b>Strategic Consulting & Planning</b></label>
          </div>
          <div *ngIf="strategicConsultingAndPlanning" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Embark on a journey of strategic excellence with our consulting and planning services.
               We delve deep into your business landscape, charting a course for growth, efficiency, and innovation.
                From insightful analysis to actionable plans, we're here to guide your organization towards its full potential.
                 Let's turn vision into strategy and strategy into success. 
            </label>
          </div>

          <!--5th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('technologyConsulting')">
            <label class="bodyTextTitle"><b>Technology Consulting & Development</b></label>
          </div>
          <div *ngIf="technologyConsulting" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Unlock the power of innovation with our cutting-edge technology services. From strategic consulting to seamless development,
               we offer a roadmap to navigate the digital landscape. Whether it's optimizing processes, creating transformative solutions,
               or staying ahead in a tech-driven world, we're your partners in progress.  
            </label>
          </div>

          <!--6th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('accountManagement')">
            <label class="bodyTextTitle"><b>Account Management</b></label>
          </div>
          <div *ngIf="accountManagement" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Maximize your brand's impact in the digital realm!  From strategic planning to daily curation, we cultivate your social
               media presence, foster community engagement, and amplify your exposure. We're the architects of engaging content,
                strategic campaigns, and meaningful connections. Let's navigate the social landscape together,
                 turning followers into fans and clicks into conversions!  
            </label>
          </div>

          <!--7th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('contentCreation')">
            <label class="bodyTextTitle"><b>Content Creation</b></label>
          </div>
          <div *ngIf="contentCreation" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Dive into a world of engaging content that captivates your audience and propels your brand forward.
               Through building a library of eye-catching images, videos, infographics, and resources,
               our services are designed to tell your story and connect with your audience in a way that leaves them craving more!  
            </label>
          </div>

          <!--8th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('campaigns')">
            <label class="bodyTextTitle"><b>Campaigns</b></label>
          </div>
          <div *ngIf="campaigns" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Elevate your brand with our strategic campaign creation services tailored for impactful results.
               Starting with concept to end with execution, we craft mouthwatering visuals and strategic storytelling,
                ensuring your brand stands out in a crowded landscape. Let's create campaigns that don't just speak but resonate.
                 Ready to make waves? 
            </label>
          </div>

          <!--8th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('connectionsPlanning')">
            <label class="bodyTextTitle"><b>Connections Planning & PR</b></label>
          </div>
          <div *ngIf="connectionsPlanning" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Crafting connections, Elevating Stories! We welcome you to the art of communication where strategic planning meets
               creative brilliance, ultimately elevating your business to new heights. Your Success, Our Story. 
            </label>
          </div>

          <!--9th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('tvcProduction')">
            <label class="bodyTextTitle"><b>TVC Production</b></label>
          </div>
          <div *ngIf="tvcProduction" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Unleash your brand's cinematic potential. Lights, Camera, Action! Our TVC production service is the gateway to visual
               storytelling. We transform your brand’s  concept into a mesmerising ad. Let's tell your story in pixels and emotions! 
            </label>
          </div>

          <!--10th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('branding')">
            <label class="bodyTextTitle"><b>Branding</b></label>
          </div>
          <div *ngIf="branding" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              From concept to recognition: We define your brand DNA. Our creative art directors and designers blend strategy with creativity to deliver brands that stand out and resonate with your audience. 
              Strategic, Creative, Impactful. It’s time to build your remarkable legacy together!
            </label>
          </div>

          <!--11th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('printingDesigns')">
            <label class="bodyTextTitle"><b>Printing Designs</b></label>
          </div>
          <div *ngIf="printingDesigns" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Printed media has long been a powerful tool for advertising, providing tangible and visually impactful ways to reach target audiences.
               From Menu designs, to brochures, billboards, posters, flyers, business cards and catalogs. We design it all for you!
            </label>
          </div>

          <!--12th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('websitesAndMobileApplication')">
            <label class="bodyTextTitle"><b>Websites & Mobile Applications</b></label>
          </div>
          <div *ngIf="websitesAndMobileApplication" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Navigate the digital frontier with our web & mobile expertise. We, at Foodo,  craft user-centric solutions tailored to amplify your business presence. 
            Discover the art of impactful web design and apps with our creative developers. . From concept to click, we craft seamless online
            experiences that captivate and maximize the search engines (SEO). Our experts track, report, and analyze website analytics, perform ongoing keyword discovery,
             expansion and optimization. We engineer digital success stories. Your business, our tech master!
            </label>
          </div>

          <!--13th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('eventsAndPlanningExecution')">
            <label class="bodyTextTitle"><b>Events Planning & Execution</b></label>
          </div>
          <div *ngIf="eventsAndPlanningExecution" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              Where precision meets pizzazz! Unleash the power of impeccable planning and dazzling execution for your business events.
               Elevate your brand, captivate your audience – because your success deserves a spectacular stage. We’re here to craft your next
                corporate masterpiece!
            </label>
          </div>

          <!--14th Devision-->
          <div style="animation: bodyAnimation2 4s;" (click)="openDiv('interiorDesign')">
            <label class="bodyTextTitle"><b>Interior Design</b></label>
          </div>
          <div *ngIf="interiorDesign" style="animation: bodyAnimation2 1s;">
            <label class="bodyText2">
              At Foodo, we believe in the transformative power of well-designed spaces. Our creative team is here to inspire and
               enhance your operations through thoughtful, innovative, and personalized interior designs.. We don’t  just design places;
                we craft stories that reflect the unique essence of your business!
            </label>
          </div>
          <div style="height: 80px;">

          </div>
        </div>
      </div>
</div>

<!--Mobile View-->
<div *ngIf="mobile" style="height: 100%; width: 100%;">
  <div class="backGroundLayout" >
    <div class="body-Content">
      <label style="animation: bodyAnimation 1.5s;" class="title">WONDERING WHAT WE DO?</label>
      <br>
      <label style="animation: bodyAnimation 2.5s;" class="subTitle">OUR PASSION MEETS</label>
      <br>
      <label style="animation: bodyAnimation 3.5s;" class="subTitle">YOUR SUCCESS</label>
    </div>
    <div style="width: 100%; text-align: center;">
      <button (click)="navigateToContactUs()" style="animation: bodyAnimation 4.5s;font-family:'Montserrat'" class="getInTouchButton">
      LET'S GET IN TOUCH
    </button>
  </div>
  </div>
  <div style="height: fit-content; width: 100%; background-color: #FAFAFB;">
    <div style="background-color: #FAFAFB; height: fit-content;">
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('businessDevelopment')">
        <label class="bodyTextTitle" style="margin-bottom: 0px; margin-top: 25px;"><b>Business Development</b></label>
      </div>
      <div *ngIf="businessDevelopment" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Elevate your F&B venture with our comprehensive business development services.
           From innovation to market strategy, we blend culinary expertise with strategic vision to create a recipe for your success.
            Let's craft a delectable journey together!
        </label>
      </div>

      <!--2nd Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('businessLeaderShip')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Business Leadership</b></label>
      </div>
      <div *ngIf="businessLeaderShip" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Experience the evolution with our strategic services that turn businesses into industry leaders.
           Through visionary leadership development and innovative organizational strategies, we pave the way for success.
           How about rewriting the narrative together, propelling your business into a league of its own!
        </label>
      </div>

      <!--3rd Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('marketing')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Marketing</b></label>
      </div>
      <div *ngIf="marketing" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Experience the power of strategic marketing tailored for success. We implement plans and targeted campaigns in order
           to amplify your brand presence and drive results. Our comprehensive services ensure your brand resonates,
            connecting with your audience in a meaningful way, covering all mediums online and offline.
             We craft compelling content, foster engagement, and boost your brand visibility. It’s time to fuel your marketing success!
        </label>
      </div>

      <!--4rth Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('strategicConsultingAndPlanning')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Strategic Consulting <br> & Planning</b></label>
      </div>
      <div *ngIf="strategicConsultingAndPlanning" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Embark on a journey of strategic excellence with our consulting and planning services.
           We delve deep into your business landscape, charting a course for growth, efficiency, and innovation.
            From insightful analysis to actionable plans, we're here to guide your organization towards its full potential.
             Let's turn vision into strategy and strategy into success. 
        </label>
      </div>

      <!--5th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('technologyConsulting')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Technology Consulting<br> & Development</b></label>
      </div>
      <div *ngIf="technologyConsulting" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Unlock the power of innovation with our cutting-edge technology services. From strategic consulting to seamless development,
           we offer a roadmap to navigate the digital landscape. Whether it's optimizing processes, creating transformative solutions,
           or staying ahead in a tech-driven world, we're your partners in progress.  
        </label>
      </div>

      <!--6th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('accountManagement')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Account Management</b></label>
      </div>
      <div *ngIf="accountManagement" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Maximize your brand's impact in the digital realm!  From strategic planning to daily curation, we cultivate your social
           media presence, foster community engagement, and amplify your exposure. We're the architects of engaging content,
            strategic campaigns, and meaningful connections. Let's navigate the social landscape together,
             turning followers into fans and clicks into conversions!  
        </label>
      </div>

      <!--7th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('contentCreation')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Content Creation</b></label>
      </div>
      <div *ngIf="contentCreation" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Dive into a world of engaging content that captivates your audience and propels your brand forward.
           Through building a library of eye-catching images, videos, infographics, and resources,
           our services are designed to tell your story and connect with your audience in a way that leaves them craving more!  
        </label>
      </div>

      <!--8th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('campaigns')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Campaigns</b></label>
      </div>
      <div *ngIf="campaigns" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Elevate your brand with our strategic campaign creation services tailored for impactful results.
           Starting with concept to end with execution, we craft mouthwatering visuals and strategic storytelling,
            ensuring your brand stands out in a crowded landscape. Let's create campaigns that don't just speak but resonate.
             Ready to make waves? 
        </label>
      </div>

      <!--8th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('connectionsPlanning')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Connections Planning & PR</b></label>
      </div>
      <div *ngIf="connectionsPlanning" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Crafting connections, Elevating Stories! We welcome you to the art of communication where strategic planning meets
           creative brilliance, ultimately elevating your business to new heights. Your Success, Our Story. 
        </label>
      </div>

      <!--9th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('tvcProduction')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>TVC Production</b></label>
      </div>
      <div *ngIf="tvcProduction" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Unleash your brand's cinematic potential. Lights, Camera, Action! Our TVC production service is the gateway to visual
           storytelling. We transform your brand’s  concept into a mesmerising ad. Let's tell your story in pixels and emotions! 
        </label>
      </div>

      <!--10th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('branding')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Branding</b></label>
      </div>
      <div *ngIf="branding" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          From concept to recognition: We define your brand DNA. Our creative art directors and designers blend strategy with creativity to deliver brands that stand out and resonate with your audience. 
          Strategic, Creative, Impactful. It’s time to build your remarkable legacy together!
        </label>
      </div>

      <!--11th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('printingDesigns')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Printing Designs</b></label>
      </div>
      <div *ngIf="printingDesigns" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Printed media has long been a powerful tool for advertising, providing tangible and visually impactful ways to reach target audiences.
           From Menu designs, to brochures, billboards, posters, flyers, business cards and catalogs. We design it all for you!
        </label>
      </div>

      <!--12th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('websitesAndMobileApplication')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Websites & Mobile Applications</b></label>
      </div>
      <div *ngIf="websitesAndMobileApplication" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Navigate the digital frontier with our web & mobile expertise. We, at Foodo,  craft user-centric solutions tailored to amplify your business presence. 
        Discover the art of impactful web design and apps with our creative developers. . From concept to click, we craft seamless online
        experiences that captivate and maximize the search engines (SEO). Our experts track, report, and analyze website analytics, perform ongoing keyword discovery,
         expansion and optimization. We engineer digital success stories. Your business, our tech master!
        </label>
      </div>

      <!--13th Devision-->
      <div style="animation: bodyAnimation2 4s;" (click)="openDiv('eventsAndPlanningExecution')">
        <label class="bodyTextTitle" style="margin-bottom: 0px;"><b>Events Planning & Execution</b></label>
      </div>
      <div *ngIf="eventsAndPlanningExecution" style="animation: bodyAnimation2 1s;">
        <br>
        <label class="bodyText2">
          Where precision meets pizzazz! Unleash the power of impeccable planning and dazzling execution for your business events.
           Elevate your brand, captivate your audience – because your success deserves a spectacular stage. We’re here to craft your next
            corporate masterpiece!
        </label>
      </div>
      <div style="height: 50px; width: 100%;">

      </div>
    </div>
  </div>
</div>


