import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { WebRequestService } from '../web-request.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog-component/dialog.component';

@Component({
  selector: 'app-contactUs',
  templateUrl: './contactUs.component.html',
  styleUrls: ['./contactUs.component.scss']
})

export class ContactUsComponent implements OnInit {
  


  web = false;
  mobile = false;
  public href: string = "";
  public screenWidth: any;
  i=1;
  name;
  email;
  phoneNumber;
  message;
  company;
  data = {
    'name': "",
    'email': "",
    'phoneNumber': "",
    'company':"",
    'message': ""
  }

  
  constructor(private router: Router, private dialog: MatDialog, private location: Location, private webRequestService: WebRequestService) {
    this.screenWidth = window.innerWidth;
    this.href = this.location.path();
    if(this.screenWidth > 1100){
      this.web = true;
    }
    if(this.screenWidth <= 1100){
      this.mobile = true;
    }
  }


  ngOnInit(): void {

  }
  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    // debugger
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;

  }
  gotourl(fragment: string) {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false; // Prevent default behavior
    }
  }




  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  sendMessage() {
    // Retrieve input field values
    this.name = (<HTMLInputElement>document.getElementById('name')).value;
    this.email = (<HTMLInputElement>document.getElementById('email')).value;
    this.phoneNumber = (<HTMLInputElement>document.getElementById('phoneNumber')).value;
    this.company = (<HTMLInputElement>document.getElementById('company')).value;
    this.message = (<HTMLInputElement>document.getElementById('message')).value;
  
    // Check if any field is empty
    if (!this.name || !this.email || !this.phoneNumber || !this.company || !this.message) {
      alert( 'Please fill in all the fields.');
      return;
    }
    
    // Validate email format
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.email.match(validRegex)) {
     alert( 'Email entered is not valid.');
      return;
    }
  
    // If all checks pass, proceed to send the message
    this.data.email = this.email;
    this.data.name = this.name;
    this.data.phoneNumber = this.phoneNumber;
    this.data.company = this.company;
    this.data.message = this.message;
  
    // Send the message
    this.webRequestService.post('sendEmail', this.data).subscribe(
      (resp) => {
        // Reset the input fields after successful sending
        this.openDialog('Success', 'Your message has been sent successfully!');
        this.clearFields();
      },
      (error) => {
        this.openDialog('Error', 'An error occurred while sending your message. Please try again later.');
      }
    );
  }
  

    openDialog(title: string, message: string): void {
      this.dialog.open(DialogComponent, {
        width: '250px',
        data: { title: title, message: message }
      });
    }
    

    clearFields() {
      // Clear the input fields
      (<HTMLInputElement>document.getElementById('name')).value = '';
      (<HTMLInputElement>document.getElementById('email')).value = '';
      (<HTMLInputElement>document.getElementById('phoneNumber')).value = '';
      (<HTMLInputElement>document.getElementById('company')).value = '';
      (<HTMLInputElement>document.getElementById('message')).value = '';
    }


  // images = [ `../assets/Images/New folder (3)/Horse Head Web Design preview - Images-051.png`];
}
