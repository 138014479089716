<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>

<div *ngIf="web" style="height: 100%;">
  <div class="backGroundLayout" >
      <div class="body-Content">
        <label style="animation: bodyAnimation 0.8s;" class="title">WANT TO GET IN TOUCH?</label>
        <br>
        <label style="animation: bodyAnimation 1.5s;" class="subTitle">WE'D LOVE TO HEAR</label>
        <br>
        <label style="animation: bodyAnimation 2s;" class="subTitle">FROM YOU</label>
      </div>
      <button #target style="animation: bodyAnimation 2.5s; font-family:'Montserrat'" class="getInTouchButton" (click)="gotourl('contactform')">
        LET’S TALK ABOUT DETAILS
      </button>
  </div>
  <div id="formContainer">
  <div id="contactform" style="height: fit-content; margin-top: 75px; text-align: center;">
      <input type="text" id="name" placeholder="Name" class="nameInput">
      <input type="number" id="phoneNumber" placeholder="Phone" class="phoneInput" (keypress)="numericOnly($event)"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
      >
      <input type="text" id="email" placeholder="Email" class="emailInput">
      <input type="text" id="company" placeholder="Company" class="companyInput">
      <input type="text" id="message" placeholder="Message" class="messageInput">
  </div>
  <button style="animation: bodyAnimation 5s;" class="sendButton" (click)="sendMessage()">
    SEND
  </button>
  </div>
  <div style="height: fit-content;">
    <label style="animation: bodyAnimation 4.5s;" class="bodyText"><b>LOOKING FOR US? HERE WE ARE</b></label>
  </div>
  <div style="display: inline-block; overflow: hidden; margin-bottom: 100px;">
    <div style="display: inline-block;">
      <img style=" animation: bodyAnimation 4.5s; max-height: 200px; max-width: 300px; height: fit-content; width: fit-content; margin-left: 100px;" src="../../assets/Images/contact-us/Lebanon.webp">
    </div>
    <div style="float: right; margin-left: 0.5rem; margin-top: -0.2rem;">
      <label style="font-family: Montserrat; animation: bodyAnimation 3.5s; color: #FAFAFB; margin-left: 10px;">Mount Lebanon - Chouf - Jdeideh</label>
      <br>
      <label style="font-family: Montserrat; animation: bodyAnimation 3.5s; color: #FAFAFB; margin-left: 10px;">Ainwzein Main Street</label>
      <br>
      <label style="font-family: Montserrat; animation: bodyAnimation 3.5s; color: #FAFAFB; margin-left: 10px;">Avenue Building - Level 2</label>
      <br><br>
      <label style="font-family: Montserrat; animation: bodyAnimation 3.5s; color: #FAFAFB; margin-left: 10px;">support@foodoglobal.com</label>
      <br><br>
      <label style="font-family: Montserrat; animation: bodyAnimation 3.5s; color: #FAFAFB; margin-left: 10px;">00961 81 81 31 18</label>
    </div>
  </div>
  <div>
    <label style="font-family: Montserrat; animation: bodyAnimation 4s; float: left; margin-left: 100px; font-size: 28px; position: absolute; top: 1195px; color: #FAFAFB;"><b>LEBANON</b></label>
  </div>
</div>

<!--Mobile View-->
<div *ngIf="mobile" style="height:fit-content; width: 100%;">
  <div class="backGroundLayout">
    <div class="body-Content">
      <label style="animation: bodyAnimation 0.8s;" class="title">WANT TO GET IN TOUCH?</label>
      <label style="animation: bodyAnimation 1.5s;" class="subTitle">WE'D LOVE TO HEAR</label>
      <label style="animation: bodyAnimation 2s;" class="subTitle">FROM YOU</label>
    </div>
    <div style="width: 100%; text-align: center;">
      <button style="animation: bodyAnimation 2.5s; font-family:Montserrat" class="getInTouchButton" (click)="gotourl('contactform')">
        LET’S TALK ABOUT DETAILS
      </button>
    </div>
  </div>
  <div id="formContainer">

  <div id="contactform" style="height: fit-content; margin-top: 50px;">
    <input type="text" id="name" placeholder="Name" class="messageInput" >
    <input type="text" id="phoneNumber" placeholder="Phone" class="messageInput" >
    <input type="text" id="email" placeholder="Email" class="messageInput" >
    <input type="text" id="company" placeholder="Company" class="messageInput" >
    <input type="text" id="message" placeholder="Message" class="messageInput" >
  </div>
  <button style="animation: bodyAnimation 5s;" class="sendButton" (click)="sendMessage()">
    SEND
  </button>
  </div>
  <div _ngcontent-jkp-c8="" class="body-text-content"><label _ngcontent-jkp-c8="" class="bodyText" style="animation: bodyAnimation 9s;"><b _ngcontent-jkp-c8="">LOOKING FOR US ? <br>HERE WE ARE<br></b></label></div>
  <div _ngcontent-pel-c8="" style="display: inline-block; overflow: hidden; margin-bottom: 100px;"><div _ngcontent-pel-c8="" style="display: inline-block;"><img _ngcontent-pel-c8="" src="../../assets/Images/contact-us/Lebanon.webp" style="animation: bodyAnimation 9s; max-height: 200px; max-width: 300px; height: fit-content; width: fit-content; margin-left: 32px;"></div><div _ngcontent-pel-c8="" style="margin-left: 32px; margin-top: 40px;"><label _ngcontent-pel-c8="" style="font-family: Montserrat; animation: bodyAnimation 9s; color: #FAFAFB;">Mount Lebanon - Chouf - Jdeideh</label><br _ngcontent-pel-c8=""><label _ngcontent-pel-c8="" style="font-family: Montserrat; animation: bodyAnimation 9s; color: #FAFAFB;">Ainwzein Main Street</label><br _ngcontent-pel-c8=""><label _ngcontent-pel-c8="" style="font-family: Montserrat; animation: bodyAnimation 9s; color: #FAFAFB;">Avenue Building - Level 2</label><br _ngcontent-pel-c8=""><br _ngcontent-pel-c8=""><label _ngcontent-pel-c8="" style="font-family: Montserrat; animation: bodyAnimation 9s; color: #FAFAFB;">support@foodoglobal.com</label><br _ngcontent-pel-c8=""><br _ngcontent-pel-c8=""><label _ngcontent-pel-c8="" style="font-family: Montserrat; animation: bodyAnimation 9s; color: #FAFAFB;">00961 81 81 31 18</label></div></div>



</div>

