
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>

<div class="d-flex justify-content-center align-items-center" *ngIf="loader" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: #191919; z-index: 9999;">
  <div class="spinner-grow" style="color: red
  ;" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<nav id="my-first">
  <ul>
    <div >
      <li >
        <img class="logo" (click)="navigateToHome()" src="../assets/Images/Foodo logo for website.png" >
      </li>
    </div>
  
    
    <li class="burger" (click)="toggleMenu()">
      <i class="material-icons">{{ isMenuOpen ? "close" : "menu" }}</i>
    </li>
    <li class="menu" [class.is-open]="isMenuOpen" style="margin-top: 15px; margin-right: 50px">
        <div *ngIf="mobile"[class.is-open]="isMenuOpen" style="margin-top:200px">
        </div>
        <a  style="animation: myAnimation 1s;" class="menu-item" routerLink="/home"  [ngClass]="{'visitedLinkColor': visitedLinks1[0]}" (click)="resetVisitedLinks(); toggleMenu(); markVisited1(0);">Your Home</a>
     
   
        <a  style="animation: myAnimation 2s;" class="menu-item" routerLink="/who-We-Are" [ngClass]="{'visitedLinkColor': visitedLinks1[1]}" (click)="resetVisitedLinks(); toggleMenu(); markVisited1(1);">Who We Are</a>
      
      
        <a style="animation: myAnimation 3s;" class="menu-item" routerLink="blogs"  [ngClass]="{'visitedLinkColor': visitedLinks1[2]}" (click)="resetVisitedLinks(); toggleMenu(); markVisited1(2);">Blogs</a>
      
        <a style="animation: myAnimation 3s;" class="menu-item" routerLink="what-We-Do"  [ngClass]="{'visitedLinkColor': visitedLinks1[3]}" (click)="resetVisitedLinks(); toggleMenu(); markVisited1(3);">What We Do</a>
        <a   style="animation: myAnimation 3s;" class="menu-item" routerLink="contact-us" [ngClass]="{'visitedLinkColor': visitedLinks[2]}" (click)="toggleMenu(); markVisited(2); navigateToContactUs(); resetVisitedLinks1();">Get In Touch</a>
    
  
    </li>
  </ul>
</nav>
<nb-layout *ngIf="!loader" >


  <!-- <nb-layout-header>
  
    <img (click)="navigateToHome()" src="../assets/Images/Foodo logo for website.webp" class="logo" width="200" height="50">

    <div style="width: 100%;">
      <div *ngIf="toggleWebNav" style=" position:absolute; width: 550px; height: 38px; margin-top: 50px; float: right; right: 200px;">
        <div style="animation: myAnimation 1s; display: inline-block; ">
          <a class="webNavLink" routerLink="home"  (click)="resetVisitedLinks();">Your Home</a>
        </div>
        <div style="animation: myAnimation 2s; display: inline-block; ">
          <a class="webNavLink" routerLink="/who-We-Are" (click)="resetVisitedLinks();">Who We Are</a>
        </div>
        <div style="animation: myAnimation 3s; display: inline-block; ">
          <a class="webNavLink" routerLink="blogs"  (click)="resetVisitedLinks();">Blogs</a>
        </div>
        <div style="animation: myAnimation 4s; display: inline-block; ">
          <a class="webNavLink" routerLink="what-We-Do"  (click)="resetVisitedLinks();">What We Do</a>
        </div>
        <div style="animation: myAnimation 5s; display: inline-block; ">
          <a class="webNavLink" routerLink="contact-us"  (click)="resetVisitedLinks();">Get In Touch</a>
        </div>
      </div>
      <div>
        <i class="material-icons headerIcon visi" (click)="toggleNavBar()" style="cursor: pointer;">menu</i>
      </div>
    </div>
    <div *ngIf="toggleMobileNav" style="position: absolute; width: 550px; height: 38px; margin-top: 50px; float: right; right: 100px;">
      <a class="mobileNavLink" (click)="toggleMobileNav = false" routerLink="/home" >Your Home</a>
      <a class="mobileNavLink" (click)="toggleMobileNav = false" routerLink="/who-We-Are">Who We Are</a>
      <a class="mobileNavLink" (click)="toggleMobileNav = false" routerLink="/blogs">Blogs</a>
      <a class="mobileNavLink" (click)="toggleMobileNav = false" routerLink="/what-We-Do">What We Do</a>
      <a class="mobileNavLink" (click)="toggleMobileNav = false" routerLink="/contact-us">Get In Touch</a>
    </div>
    
    <div>
      <i class="material-icons headerIcon visim" (click)="toggleNavBar()" style="cursor: pointer;">menu</i>
    </div>
  </nb-layout-header>  -->
  <nb-layout-header style="margin-top:500px">
  </nb-layout-header>

  <nb-layout-column style="width: 100%;">
    <router-outlet></router-outlet>
  </nb-layout-column>

  
    <nb-layout-footer>
      
      <div *ngIf="web" class="footer-links">
        <!-- <p class="bold-text">Foodo Digital {{ year }} </p> -->
        <a class="footerText" (click)="markVisited(0);resetVisitedLinks1(); navigateToPrivacyPolicy();" [ngClass]="{'visitedLinkColor': visitedLinks[0]}">Privacy Policy</a>
        <a class="footerText" (click)="markVisited(1);resetVisitedLinks1(); navigateToTermsConditions()" [ngClass]="{'visitedLinkColor': visitedLinks[1]}">Terms & Conditions</a>
        <a  class="footerSeperateText" (click)="markVisited(2);resetVisitedLinks1();; navigateToContactUs();"  [ngClass]="{'visitedLinkColor': visitedLinks[2]}">GET IN TOUCH</a>
        <a href="http://wa.me/96181813118" class="footerText" (click)="markVisited(3);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[3]}">Whatsapp</a>
        <a href="https://www.instagram.com/foodoglobal/" target="_blank" class="footerText" (click)="markVisited(4);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[4]}">Instagram</a>
        <a href="https://www.facebook.com/foodoglobal" class="footerText" (click)="markVisited(5);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[5]}">Facebook</a>
        <a href="https://www.linkedin.com/company/foodoglobal/" class="footerText" (click)="markVisited(6);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[6]}">Linkedin</a>
      </div>
      <div *ngIf="mobile" class="footer-links">
            <a class="bold-text">Foodo {{ year }} </a> 
  
        <a  class="footerText" (click)="markVisited(0);resetVisitedLinks1(); navigateToPrivacyPolicy();" [ngClass]="{'visitedLinkColor': visitedLinks[0]}">Privacy Policy</a>
        <a  class="footerText" (click)="markVisited(1);resetVisitedLinks1(); navigateToTermsConditions();" [ngClass]="{'visitedLinkColor': visitedLinks[1]}">Terms & Conditions</a>
        <!-- <a class="footerText" (click)="markVisited(2);navigateToContactUs();resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[2]}">GET IN TOUCH</a> -->
        <br/>
        <a href="http://wa.me/96181813118" class="footerText" (click)="markVisited(3);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[3]}">Whatsapp</a>
        <a href="https://www.instagram.com/foodoglobal/" target="_blank" class="footerText" (click)="markVisited(4);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[4]}">Instagram</a>
        <a href="https://www.facebook.com/foodoglobal" class="footerText" (click)="markVisited(5);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[5]}">Facebook</a>
        <a href="https://www.linkedin.com/company/foodoglobal/" class="footerText" (click)="markVisited(6);resetVisitedLinks1();" [ngClass]="{'visitedLinkColor': visitedLinks[6]}">Linkedin</a>
    
      </div>
    </nb-layout-footer>
  <!-- <nb-layout-footer>
    <div *ngIf="web" class="footer-links">
      <a href="#" class="footerText" (click)="markVisited(0)" [ngClass]="{'visitedLinkColor': visitedLinks[0]}">Privacy Policy</a>
      <a href="#" class="footerText" (click)="markVisited(1)" [ngClass]="{'visitedLinkColor': visitedLinks[1]}">Terms & Conditions</a>
      <a href="#" class="footerText" (click)="markVisited(2)" (click)="navigateToContactUs()" [ngClass]="{'visitedLinkColor': visitedLinks[2]}">GET IN TOUCH</a>
      <a href="http://wa.me/96181813118" class="footerText" (click)="markVisited(3)" [ngClass]="{'visitedLinkColor': visitedLinks[3]}">Whatsapp</a>
      <a href="https://www.instagram.com/foodoglobal/" target="_blank" class="footerText" (click)="markVisited(4)" [ngClass]="{'visitedLinkColor': visitedLinks[4]}">Instagram</a>
      <a href="https://www.facebook.com/foodoglobal" class="footerText" (click)="markVisited(5)" [ngClass]="{'visitedLinkColor': visitedLinks[5]}">Facebook</a>
      <a href="https://www.linkedin.com/company/foodoglobal/" class="footerText" (click)="markVisited(6)" [ngClass]="{'visitedLinkColor': visitedLinks[6]}">Linkedin</a>
    </div>
    
    <div *ngIf="mobile" >
     
      <div *ngIf="web" class="footer-links">
        <a href="#" class="footerText">Privacy Policy</a>
        <a href="#" class="footerText">Terms & Conditions</a>
        <a href="#" class="footerText" (click)="navigateToContactUs()">GET IN TOUCH</a>
        <a href="http://wa.me/96181813118" class="footerText">Whatsapp</a>
        <a href="https://www.instagram.com/foodoglobal/" target="_blank" class="footerText">Instagram</a>
        <a href="https://www.facebook.com/foodoglobal" class="footerText">Facebook</a>
        <a href="https://www.linkedin.com/company/foodoglobal/" class="footerText">Linkedin</a>
    </div>
    </div>
  </nb-layout-footer> -->

</nb-layout>
