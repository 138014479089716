import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  web = false;
  mobile = false;
  public href: string = "";
  public screenWidth: any;

  constructor(private router: Router,  private location: Location) { 
    this.screenWidth = window.innerWidth;
    this.href = this.location.path();
    if(this.screenWidth > 1200){
      this.web = true;
    }
    if(this.screenWidth <= 1200){
      this.mobile = true;
    }
  }

  ngOnInit(): void {

  }

  navigateToContactUs(){
    this.router.navigateByUrl('/contact-us');
  }
}
