import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Model } from './model/model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'shell-project';
  loader = true;
  year: number;
  web = false;
  mobile = false;
  public href: string = "";
  public screenWidth: any;

  toggleMobileNav = false;
  toggleWebNav = false;

  constructor(private router: Router, public model: Model, private location: Location) {
    this.year = new Date().getFullYear();
    this.screenWidth = window.innerWidth;
    this.href = this.location.path();
    if (this.screenWidth > 1200) {
      this.web = true;
    }
    if (this.screenWidth <= 1200) {
      this.mobile = true;
    }
  }
  isMenuOpen = false;

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;}

  visitedLinks: boolean[] = JSON.parse(localStorage.getItem('visitedLinks')) || [false, false, false, false, false, false, false];
  visitedLinks1: boolean[] = JSON.parse(localStorage.getItem('visitedLinks1')) || [false, false, false, false];

  ngOnInit(): void {

    // Hide loader after 3 seconds
    setTimeout(() => {
      this.loader = false;
    }, 3000);
    this.updateLocalStorage();
    this.updateLocalStorage1();
  }



  markVisited(index: number) {
    this.visitedLinks.fill(false);
    this.visitedLinks[index] = true;
    this.updateLocalStorage();
  }
  markVisited1(index: number) {
    this.visitedLinks1.fill(false);
    this.visitedLinks1[index] = true;
    this.updateLocalStorage1();
  }

  private updateLocalStorage() {
    localStorage.setItem('visitedLinks', JSON.stringify(this.visitedLinks));
  }
  private updateLocalStorage1() {
    localStorage.setItem('visitedLinks1', JSON.stringify(this.visitedLinks1));
  }
    // Function to reset all links to unvisited when a main menu item is clicked
    resetVisitedLinks() {
      this.visitedLinks.fill(false);
      this.updateLocalStorage();
    }

        // Function to reset all links to unvisited when a main menu item is clicked
        resetVisitedLinks1() {
          this.visitedLinks1.fill(false);
          this.updateLocalStorage1();
        }


  // Method to handle "Let's Get In Touch" button click
  handleGetInTouchClick() {
    // Reset menu colors
    this.resetVisitedLinks1();
    // Mark "Get In Touch" as visited in the footer
    this.markVisited(2); // Assuming 2 is the index for "Get In Touch" in your footer
    // Navigate to the contact page
    this.navigateToContactUs();
  }

  navigateToHome() {
    this.router.navigateByUrl('/home');
  }

  navigateToPrivacyPolicy() {
    this.router.navigateByUrl('/privacy-policy');
  }

  navigateToTermsConditions() {
    this.router.navigateByUrl('/terms-conditions');
  }

  navigateToContactUs() {
    this.router.navigateByUrl('/contact-us');
  }

  navigateToWP() {
    this.router.navigateByUrl('http://wa.me/81813118');
  }

  toggleNavBar() {
    if (this.screenWidth <= 1200) {
      this.toggleWebNav = false;
      this.toggleMobileNav = !this.toggleMobileNav;
    } else {
      this.toggleMobileNav = false;
      this.toggleWebNav = !this.toggleWebNav;
    }
  }
}
