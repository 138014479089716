<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>

<div *ngIf="web" style="height: 100%;">

    <div class="backGroundLayout">
        <div class="body-Content">
            <label class="title" style="font-family: 'Poppins';">Privacy  Policy</label>
        </div>
            <button (click)="navigateToContactUs()" class="getInTouchButton" style="font-family: 'Montserrat';">
                LET'S GET IN TOUCH
            </button>
        
    </div>
    <div style="background-color: #FAFAFB; height: fit-content;">
        <label class="bodyText2" style="font-family: 'Poppins';">
            Foodo ("we", "our", or "us") is committed to protecting the privacy and security of your personal information.
            This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our
            website or otherwise engage with our marketing services in the Food & Beverage (F&B) sector.
        </label>
        <label class="bodyText" style="font-family: 'Poppins';"><b>Information We Collect</b></label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            We may collect personal information that you voluntarily provide to us when you interact with our website,
            services, or promotions. This information may include but is not limited to:
            <ul  class="privacy-list" style="margin-top: 5px; margin-bottom: 5px;">
                <li>Personal details such as name, email address, phone number, and postal address.</li>
                <li>Information about your business or organization.</li>
                <li>Communication preferences.</li>
                <li>Information about your interactions with our website and services, such as IP address, browser type,
                    referring/exit pages, and operating system.</li>
            </ul>
        </label>
        <br>

        <label class="bodyText" style="font-family: 'Poppins';"><b>How We Use Your Information</b></label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins';">
            We may use the information we collect for various purposes, including:
            <ul  class="privacy-list" style="margin-top: 5px; margin-bottom: 5px;">
                <li>To provide, operate, and improve our services.</li>
                <li>To communicate with you, including responding to your inquiries and providingcustomer support.</li>
                <li>To personalize your experience and tailor our services to your preferences.</li>
                <li>To analyze trends and user behavior.</li>
                <li>To comply with legal and regulatory obligations.</li>
            </ul>
        </label>
        <br>

        <label class="bodyText" style="font-family: 'Poppins';"><b>Data Sharing and Disclosure</b></label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            We may share your personal information with third parties under the following circumstances:
            <ul class="privacy-list" style="margin-top: 5px; margin-bottom: 5px;">
                <li>With service providers and business partners who assist us in providing our services or performing
                    business functions on our behalf.</li>
                <li>With your consent or at your direction.</li>
                <li>In connection with a business transaction, such as a merger, acquisition, or sale of assets.</li>
                <li>To comply with legal obligations or to protect our rights, property, or safety, or that of others.</li>
            </ul>
        </label>
        <br>

        <label class="bodyText" style="font-family: 'Poppins';"><b>Data Security</b></label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            We take reasonable measures to protect the confidentiality, integrity, and security of your personal
            information. However, please be aware that no method of transmission over the internet or method of
            electronic storage is 100% secure.
        </label>
        <br>
        <label class="bodyText" style="font-family: 'Poppins';"><b>Your Choices and Rights</b></label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            You have the right to access, correct, or delete your personal information. You may also opt-out of receiving
            marketing communications from us at any time. To exercise these rights or for any inquiries regarding your
            personal information, please contact us using the contact information provided below.
        </label>
        <label class="bodyText" style="font-family: 'Poppins';"><b>Changes to This Privacy Policy</b></label>
        <label class="bodyText2" style="font-family: 'Poppins';">
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
            We will notify you of any material changes by posting the updated Privacy Policy on our website or through other
            means.
        </label>
        <label class="bodyText" style="font-family: 'Poppins';"><b>Contact Us</b></label>
        <br>
        <label class="bodyText2" style="font-family: 'Poppins'; margin-bottom: 30px;">
            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
            <br>
            <div class="support_container">
           <span class="support_link"> Email : </span> <a class="support_link" href="mailto:support@foodoglobal.com">support@foodoglobal.com</a><br>
           <span class="support_link"> Phone : </span> <a class="support_link" href="tel:0096181813118">00961 81 81 31 18</a>
       </div>
        </label>
    </div>
</div>  
