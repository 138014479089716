<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
<div *ngIf="web">
<div style="height: 100%; display: inline-block; overflow: hidden;">
  <div class="body-Content">
      <label style="animation: bodyAnimation 2s;" class="title">WE'RE THE GROWTH TASTE</label>
    <br>
    <label style="animation: bodyAnimation 3s;" class="subTitle">SERVED TO SATISFY THE</label>
    <br>
    <label style="animation: bodyAnimation 3s;" class="subTitle">WORLD'S CRAVINGS</label>
  </div>
  <button (click)="navigateToContactUs();  " style="animation: bodyAnimation 3s; font-family:'Montserrat'" class="getInTouchButton">
    LET'S GET IN TOUCH
  </button>
</div>
  <img src="../../assets/gif/Animated GIF.gif" class="gifComponent" >
</div>
<div *ngIf="mobile">
  <div style="height: 100%; display: inline-block; overflow: hidden;">
    <div class="body-Content">
      <label style="animation: bodyAnimation 2s;" class="title">WE'RE THE GROWTH TASTE</label>
      <br>
      <label style="animation: bodyAnimation 3s;" class="subTitle">SERVED TO SATISFY THE</label>
      <br>
      <label style="animation: bodyAnimation 3s;" class="subTitle">WORLD'S CRAVINGS</label>
    </div>
    <div style="width: 100%; text-align: center;">
      <button (click)="navigateToContactUs()" style="animation: bodyAnimation 3s; font-family:'Montserrat'" class="getInTouchButton">
        LET'S GET IN TOUCH
      </button>
    </div>
  </div>
  <img src="../../assets/gif/Animated GIF.gif" class="gifComponent" >
</div>
