import { Component, OnInit } from '@angular/core';
import { TaskService } from '../task.service';
import { Model } from '../model/model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  web = false;
  mobile = false;
  public href: string = "";
  public screenWidth: any;

  constructor(private router: Router,public model: Model, private location: Location) {
    this.screenWidth = window.innerWidth;
    this.href = this.location.path();
    if(this.screenWidth > 1200){
      this.web = true;
    }
    if(this.screenWidth <= 1200){
      this.mobile = true;
    }
  }

  ngOnInit(): void {

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  navigateToContactUs(){
    this.router.navigateByUrl('/contact-us');
  }
}

