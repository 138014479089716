import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { WhoWeAreComponent } from './whoWeAre/whoWeAre.component';
import { Model } from './model/model';
import { ItemsComponent } from './cardComponent/items.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ContactUsComponent } from './get In Touch/contactUs.component';
import { BlogsComponent } from './blogs/blogs.component';
import { WhatWeDoComponent } from './whatWeDo/whatWeDo.component';
import { DialogComponent } from './dialog-component/dialog.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';





@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WhoWeAreComponent,
    ItemsComponent,
    BlogsComponent,
    WhatWeDoComponent,
    ContactUsComponent,
    DialogComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NgbModule,
    FormsModule,
    IvyCarouselModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [WhoWeAreComponent, HomeComponent, Model, PrivacyPolicyComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
